<template>
  <j-abm
    name="token"
    :suppliers="suppliers"
    :features="{
      search: false,
      create: true,
      update: true,
      delete: false,
    }"
    @create="onCreate"
    @update="onUpdate">
    <template #form="{item}">
      <div class="col-xl-5 col-lg-12 mb-2">
        <h5>{{ $t('token') }}</h5>
      </div>
      <div class="col-xl-7 col-lg-12">
        <a-form-model
            ref="form"
            :model="item"
            :rules="rules">
          <j-input
            prop="name"
            v-model="item.name"
            icon="number" />
          <j-field
            prop="scope">
            <a-checkbox-group
              v-model="item.scope"
              :options="scopes" />
          </j-field>
          <j-field
            prop="active">
            <a-switch v-model="item.active" />
          </j-field>
        </a-form-model>
      </div>
    </template>
  </j-abm>
</template>

<script>
import lms from '@/api/lms'
import v from '@/utils/validators'
import f from '@/utils/formatters'
import JAbm from '@/views/shared/abm/update'
import JInput from '@/views/shared/forms/input'
import JField from '@/views/shared/forms/field'
import { getParentPath } from '@/utils/paths'
export default {
  components: {
    JAbm,
    JInput,
    JField,
  },
  data: function () {
    return {
      f,
      rules: {
        method: [v.required],
        currency: [v.required],
        code: [v.required, v.number],
        value: [v.required, v.number],
      },
    }
  },
  computed: {
    scopes() {
      return this.$store.getters['session/USER'].pat
    },
    suppliers() {
      return {
        fetchOne: id => lms.account.fetchToken(this.account, id),
        payload: () => {
          return {
            type: 'ACCOUNT',
            subject: this.account,
            active: true,
          }
        },
      }
    },
  },
  methods: {
    onCreate(token) {
      const path = this.$route.path
      this.onSubmit(token, token => lms.account.createToken(this.account, token)
        .then(_ => this.$router.push(getParentPath(path, 1))))
    },
    onUpdate(token) {
      const path = this.$route.path
      this.onSubmit(token, token => lms.account.updateToken(this.account, token)
        .then(_ => this.$router.push(getParentPath(path, 2))))
    },
    onSubmit(token, callback) {
      Promise.all([
        new Promise((resolve, reject) => this.$refs.form.validate(resolve)),
      ]).then(results => {
        if (results.every(Boolean)) {
          callback(token)
        }
      })
    },
  },
}
</script>
